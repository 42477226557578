import(/* webpackMode: "eager" */ "/workspace/app/globals.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/app/google-tag-manager.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/components/BottomFixedMenus/BottomFixedMenus.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/components/Footer/Footer.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/components/Navbar/Navbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["NextUiProviders"] */ "/workspace/providers/NextUiProvider.tsx");
