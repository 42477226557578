"use client";
import { useTranslations } from "next-intl";
import Image from "next/image";
import React, { useState } from "react";
import { FaChevronDown, FaTimes } from "react-icons/fa";
import styles from "./Sidebar.module.scss";
import { useRouter } from "../../i18n/routing";

const Sidebar = ({ isOpen, setIsOpen, menu, data }) => {
  const t = useTranslations("Header");
  const [isToggle, setIsToggle] = useState(false);
  const router = useRouter();
  const closeSidebar = () => {
    setIsOpen(false);
  };

  const changeRoute = (path: string) => {
    if (path && path !== "#") {
      router.push(path);
      closeSidebar();
      setIsToggle(false);
    } else {
      setIsToggle(!isToggle);
    }
  };

  const changeChildRoute = (slug: string) => {
    router.replace("/our-services/" + slug);
    closeSidebar();
    setIsToggle(false);
  };

  return (
    <div className={`${styles.sidebar} ${isOpen && styles.active}`}>
      <div className={styles.header}>
        <div className="mb-8">
          <Image
            src={data?.site_logo_alt?.data?.attributes?.url}
            alt={
              data?.site_logo_alt?.data?.attributes?.alternativeText || "logo"
            }
            priority
            width={120}
            height={120}
          />
        </div>
        <button
          className={styles.header__toggler}
          onClick={closeSidebar}
          aria-label="Close Sidebar"
        >
          <FaTimes />
        </button>
      </div>
      <div className={styles.menu}>
        {menu.map(({ label, path, children }: any, index: React.Key) => (
          <div key={index}>
            <button
              key={index}
              onClick={() => changeRoute(path)}
              className={
                styles.menu__link + " flex items-center justify-between w-full"
              }
              aria-label={label}
            >
              {t(label)}{" "}
              {children && (
                <span>
                  <FaChevronDown className="w-4 h-4" />
                </span>
              )}
            </button>
            {children?.length && (
              <div
                className={`overflow-hidden duration-200 ease-linear ${
                  isToggle ? "h-full" : "max-h-0 "
                }`}
              >
                {children.map((child: any, index: number) => {
                  return (
                    <button
                      key={index}
                      className="px-4 p-2 text-lg text-left"
                      onClick={() =>
                        changeChildRoute(`${child?.attributes?.slug}`)
                      }
                      aria-label={child?.attributes?.title}
                    >
                      {child?.attributes?.title}
                    </button>
                  );
                })}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
